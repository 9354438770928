import { oneTimeSetup, sharedSetup, logCustomDatadogAction, logDatadogError, setDatadogRumContext } from "app/scripts/shared_setup"
import { attachNewBadgeForButtons } from "shared/scripts/new_badge_button.tsx"
import VanillaFittty from "shared/scripts/vanilla_fittty.tsx"
import RubberBandPinchColor from "app/scripts/rubber_band_pinch_color"
import BackButton from "app/scripts/back_button"
import toggleHelpWidgetVisibility from "shared/scripts/toggle_help_widget_visibility"
import lazyBGImageObserver from "app/scripts/lazy_bg_img_observer"
import SetupUnifiedMobileMenu from "shared/scripts/setup_unified_mobile_menu"

function viteSetup() {
  oneTimeSetup()

  document.addEventListener("DOMContentLoaded", () => {
    sharedSetup()
    VanillaFittty()
    attachNewBadgeForButtons()
    RubberBandPinchColor({ updateBottomBg: true })
    SetupUnifiedMobileMenu()

    const tryProFreeButton = document.getElementById("try-pro-free")
    tryProFreeButton?.addEventListener("click", () => {
      logCustomDatadogAction?.("click_try_pro_free")
    }, { once: true })

    lazyBGImageObserver()
    if (window.App.user.id) toggleHelpWidgetVisibility()
  })

  window.App = window.App || {}
  window.App = Object.assign(window.App, {
    storedFieldPath: (resolveUrl, liveChannelId = "") => {
      if (liveChannelId === "") {
        return `/stored_fields/options?resolve_url=${resolveUrl}`
      } else {
        return `/stored_fields/options?resolve_url=${resolveUrl}&live_channel_id=${liveChannelId}`
      }
    }
  })
  window.App.Comps = {}
  window.App.Scripts = {
    BackButton
  }
  window.App.Utils = {
    logCustomDatadogAction,
    logDatadogError
  }
}

export { setDatadogRumContext, viteSetup }
